/* ====== FUNCTIONS FONTS ====== */

/*
** Pixels to em
** Usage: font-size: em(16);
** Output: font-size: 1em;
*/
@function em($pixels, $context: $browser-context) {
	@return $pixels / $context * 1em;
}

@font-face {
	font-family: 'icomoon';
	src:  url('/fonts/icomoon.eot?69hkpp');
	src:  url('/fonts/icomoon.eot?69hkpp#iefix') format('embedded-opentype'),
	url('/fonts/icomoon.ttf?69hkpp') format('truetype'),
	url('/fonts/icomoon.woff?69hkpp') format('woff'),
	url('/fonts/icomoon.svg?69hkpp#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
	content: "\e942";
}
.icon-envelop:before {
	content: "\e945";
}
.icon-test:before {
	content: "\e900";
	color: #fff;
}
.icon-optimise:before {
	content: "\e901";
	color: #fff;
}
.icon-develop:before {
	content: "\e902";
	color: #fff;
}
.icon-discover:before {
	content: "\e903";
	color: #fff;
}
