.reveal {
	transition: opacity 400ms ease-in 0s, transform 400ms ease-in 0s;
	opacity: 0;
	transform: translate3d(0, 50%, 0);

	&--from-left {
		transform: translate3d(-50%, 0, 0);
	}

	&--from-right {
		transform: translate3d(50%, 0, 0);
	}
}

.reveal.shown {
	opacity: 1;
	transform: translate3d(0,0,0);
}