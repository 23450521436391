table.definitions {
	font-size: 0.875em;
	line-height: 1.6;
	border-top: 1px solid #EAEAEA;

	th {
		display: block;
		width: auto;
		font-weight: bold;
		text-align: left;
		padding: 0;
	}

	td {
		display: block;
		width: auto;
		text-align: left;
		padding: 0 0 1em;
	}

	@include breakpoint('small') {
		th {
			display: table-cell;
			padding: 0.5em 1em 0.5em 0;
			border-bottom: 1px solid #EAEAEA;
		}

		td {
			display: table-cell;
			padding: 0.5em 0;
			border-bottom: 1px solid #EAEAEA;
		}
	}

	&.personal-data {
		thead { display: none; }

		tbody tr td::before {
			display: block;
			font-weight: bold;
		}

		tbody tr td:nth-child(1)::before { content: "Category of personal data"; }
		tbody tr td:nth-child(2)::before { content: "Purpose of processing"; }
		tbody tr td:nth-child(3)::before { content: "Lawlful basis for processing"; }
		tbody tr td:nth-child(4)::before { content: "Retention period"; }

		tbody tr { display: block; border-bottom: 1px solid #EAEAEA; padding-top: 1em; }

		@include breakpoint('small') {
			border-left: 1px solid #EAEAEA;
			border-right: 1px solid #EAEAEA;
			border-collapse: collapse;
			margin: 2em 0;

			thead { display: table-row-group; }

			thead tr th,
			tbody tr td { padding: 0.5em 1em; border: 1px solid #EAEAEA; }

			tbody tr td::before { display: none; }

			tbody tr { display: table-row; border-bottom: none; padding-top: 0; }
		}
	}
}