
.container {
	max-width: $container-width;
	margin: 0 auto;
	padding: 3em 2em;
}

@include breakpoint('tiny') {
	.container {
		padding: 4em 2em;
	}
}

@include breakpoint('small') {
	.container {
		padding: 6em 2em;
	}
}
/*

@include breakpoint('large') {
	.container {
		padding: 6em 2em;
	}
}*/
