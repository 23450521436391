.btn {
	background: $orange;
	border: 1px solid $orange;
	border-radius: 3px;
	color: $white;
	display: inline-block;
	padding: .45em 1em;

	&:hover {
		background: transparent;
		color: $orange;
		text-decoration: none;
		transition: background-color .2s ease-in, color .2s ease-in;
	}
}

.btn--black {
	background: $black;
	border-color: $black;
	color: $white;

	&:hover {
		color: $black;
	}
}