.what-we-do {
	background: $orange url('/images/triangles.png') bottom left no-repeat;
	background-size: auto 100%;
	color: white;
	text-align: center;
}

.what-we-do .blocks {
	margin: 2em 0 0;
}

.what-we-do .block {
	margin: 0 0 2em;
}

.what-we-do .block:last-of-type { margin-bottom: 0; }

.what-we-do .block .top {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1em;
	margin: 1em 0;
}

.what-we-do .block .top .title {
	font-size: em(18);
	font-weight: bold;
	text-transform: uppercase;
}

.what-we-do .block .top span {
	font-size: em(26);
}

.what-we-do .block {
	transition: opacity 400ms ease-in 0s, transform 400ms ease-in 0s;

	@for $i from 1 through 4 {
		&.shown:nth-child(#{$i}) {
			transition: transform .2s ease #{$i * .2}s, opacity .2s ease #{$i * .2}s;
		}
	}
}

@include breakpoint('small') {

	.what-we-do .container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.what-we-do .block .top {
		justify-content: flex-start;
	}

	.what-we-do .blocks {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 1em;
		text-align: left;
	}

	.what-we-do .blocks .block {
		width: 45%;

		&:nth-last-child(-n+2) {
			margin-bottom: 0;
		}

	}

}

@include breakpoint('large') {

	.what-we-do .container {
		flex-direction: row;
	}

	.what-we-do .tagline {
		width: 40%;
		text-align: left;
	}

	.what-we-do .blocks {
		margin: 0 0 0;
		width: 60%;
	}

}