/* ====== MIXINS BREAKPOINTS ====== */

@mixin breakpoint($name) {
	@if not map-has-key($breakpoints, $name) {
		@warn "Warning: `#{$name}` is not a valid breakpoint name.";
	} @else {
		@media (min-width: map-get($breakpoints, $name)) {
			@content;
		}
	}
}
