.banner {
	background: $black url('/images/banner-full.png') bottom center no-repeat;
	background-size: contain;
	position: relative;
	overflow: hidden;

	&:before {
		content: "";
		background: black;
		opacity: .6;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	@include breakpoint('medium') {
		background: $black;
	}

}

.banner .container {
	padding-top: 0;
	padding-bottom: 0;
	max-width: 1280px;
	position: relative;
	z-index: 4;

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: (31 / 52) * 100%;

		@include breakpoint('medium') {
			padding-top: (31 / 72) * 100%;
		}
	}
}

.banner .container .content {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 2em;
}

.banner .container .content h1 {
	text-align: center;

	&:before, &:after { color: $orange; }
}

@include breakpoint('small') {
	.banner .container .content {
		padding: 0 2em;
		align-items: flex-start;
	}
	.banner .container .content h1 {
		text-align: left;
	}
}

.banner .container .parallax-layer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: auto;
	width: 100%;
}

.banner h1 {
	color: $white;
	position: relative;
	z-index: 2;

	@include breakpoint('small') {
		max-width: 55vw;
		margin: 0;
	}

	@include breakpoint('medium') {
		max-width: 500px;
	}
}

.banner .layer {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	transition: transform 1s linear 0s;
	backface-visibility: hidden;

	@include breakpoint('medium') {
		display: block;
	}

}

.banner .layer.layer-main {
	left: 45%;
	top: 25%;
}

.banner .layer.layer-white-outline-1 {
	left: 48%;
	top: 18%;
}

.banner .layer.layer-white-outline-2 {
	top: 60%;
	left: 90%;
}

.banner .layer.layer-green-white {
	top: 55%;
	left: 75%;
}

.banner .layer.layer-orange {
	top: 75%;
	left: 100%;
}