.list--report {
	counter-reset: list list2 list3 list4;

	[data-report-level] {
		.counter {
			padding-left: 1.5em;
			position: relative;
			counter-increment: list;

			&::before {
				content: counter(list);
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				font-weight: bold;
			}
		}
	}

	[data-report-level="1"] {
		counter-reset: list list2 list3 list4;

		.counter::before { text-transform: uppercase; }
	}

	[data-report-level="2"] {
		counter-reset: list2 list3 list4;

		.counter {
			counter-increment: list2;
			padding-left: 2.5em;

			&::before {
				content: counter(list) "." counter(list2);
			}
		}
	}

	[data-report-level="3"] {
		counter-reset: list3 list4;
		margin-left: 2.5em;

		.counter {
			counter-increment: list3;
			padding-left: 3.5em;

			&::before {
				content: counter(list) "." counter(list2) "." counter(list3);
			}
		}
	}

	[data-report-level="4"] {
		margin-left: 3.5em;
		counter-reset: list4;

		.counter {
			counter-increment: list4;
			padding-left: 4.5em;

			&::before {
				content: counter(list) "." counter(list2) "." counter(list3) "." counter(list4);
			}
		}
	}
}