.quotes {
	background: $grey;
}

.quotes .container .quote-slider {
	display: flex;
	flex-direction: column;
	gap: 2em;
	position: relative;
}

.quote-slider .quote {
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 2em;
}

.quote-slider .quote .text {
	font-size: em(20);
}


.quote-slider .quote .text span {
	display: block;
	font-size: .9em;
	font-weight: 600;
	color: $teal;
}

.quote-slider .controls {
	margin: 2em auto 0;
	text-align: center;
}

.quote-slider .blaze-prev, .quote-slider .blaze-next { display: none; }

.quote-slider .blaze-pagination {
	display: flex;
	flex-direction: row;
	gap: 1em;
	justify-content: center;
}

.quote-slider .blaze-pagination button {
	background: $darkGrey;
	border: 0;
	color: $orange;
	text-indent: -99999px;
	height: .35em;
	width: 3.5em;
	display: inline-block;
	clip-path: polygon(0 0, 95% 0, 100% 100%, 5% 100%);

	&.active {
		background: $orange;
	}
}

@include breakpoint('small') {
	.quote-slider .controls {
		margin-bottom: 0;
	}
}

@include breakpoint('medium') {
	.quote-slider .quote .text {
		max-width: 650px;
		margin: 0 auto;
	}
}

@include breakpoint('large') {

	.quotes { position: relative; }
	.quotes:after {
		content: '';
		background: #e9e9e9;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		clip-path: polygon(65% 0, 100% 0, 100% 100%, 85% 100%);
	}

	.quotes .container {
		position: relative;
		z-index: 2;
	}

	.quote-slider .blaze-slider {

	}

	.quote-slider .quote {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 2em;
		text-align: left;
	}

	.quote-slider .quote .logo { width: 35%; }
	.quote-slider .quote .text { width: 70%; padding-right: 2em; max-width: none; }

	.blaze-container {

	}

	.quote-slider .controls {
		margin-bottom: 0;
	}
	.quote-slider .controls .blaze-pagination {
		justify-content: flex-start;
	}
}