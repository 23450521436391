
/* ==========================================================================
$	COLOURS
========================================================================== */

$black: #000000;
$white: #ffffff;
$orange: #FF6326;
$teal: #1EADA3;
$grey: #EDEDED;
$darkGrey: #CECECE;
$footerLinksGrey: #909090;


/* ==========================================================================
$	FONTS
========================================================================== */

$browser-context: 16 !default;

$font-family-sans-serif: 'Work Sans', sans-serif;
$font-family-base: $font-family-sans-serif !default;

// sizes
$font-size-base: 1em !default;

// weights
$font-weight-normal: 400 !default;

/* ==========================================================================
$	BREAKPOINTS
========================================================================== */
$breakpoints: (
		tiny: 520px,
		small: 768px,
		medium: 960px,
		large: 1280px,
		wide: 1440px,
		widest: 1680px
);

/* ==========================================================================
$	LAYOUT
========================================================================== */

$container-width: 1280px;