.work-with {
	text-align: center;
}

.work-with .tagline {
	margin: 0 0 4em;
}

.work-with .tagline h2 { display: inline-block; position: relative; max-width: 440px; }
.work-with .tagline h2:before,
.work-with .tagline h2:after { color: $orange; } /*{
	content: "";
	background: $orange;
	height: 15px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 25%;
	max-width: 75px;
	transform-origin: bottom left;
	transform: rotate(55deg) translate3d(-80%,80%,0) skew(-37deg);
}*/

.work-with .logos {
	display: grid;
	grid-template-columns: auto auto;
	gap: 2em 1em;
	margin: 3em 0 0;
	width: 100%;
}

.work-with .logos div {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	transition-delay: 0s;
	@for $i from 1 through 12 {
		&.shown:nth-child(#{$i}) {
			transition: transform .2s ease #{$i * .1}s, opacity .2s ease #{$i * .2}s;
		}
	}

}
.work-with .logos div img {
	object-fit: cover;
}

@include breakpoint('small') {
	.work-with .logos {
		grid-template-columns: auto auto auto;
	}
}

@include breakpoint('large') {

	.work-with .container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 2em;
	}

	.work-with .tagline {
		text-align: left;
		width: 45%;
		margin: 0;
	}

    .work-with .tagline h2 {
        max-width: none;
    }

	/*
	.work-with .tagline h2:after {
		content: "";
		background: $orange;
		height: 15px;
		position: absolute;
		bottom: -.5em;
		left: -.5em;
		width: 25%;
		max-width: 75px;
		transform-origin: bottom left;
		transform: rotate(55deg) translate3d(-80%,80%,0) skew(-37deg);
	}
	*/

	.work-with .logos { margin: 0; width: 55%; 	gap: 4em 1em; }

}
