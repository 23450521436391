.form {
	background: $black;
	position: relative;
}

@include breakpoint('small') {
	.form::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #0e524d;
		clip-path: polygon(12% 0, 30% 100%, 13% 100%, 0 16%, 0 0);
	}
}

.form .container {
	position: relative;
	z-index: 2;
	color: white;
}

.contact-form .form-row {
	position: relative;
	margin: 0 0 2em;
}

.contact-form label {
	position: relative;
	display: block;
	text-align: left;
	border-bottom: 1px solid white;
	padding-bottom: .75em;
	font-size: em(15);
}

.contact-form span {
	pointer-events: none;
	position:absolute;
	left:0;
	top:0;
	transition: 0.2s;
	transition-timing-function: ease;
	transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	opacity:1;
	display: block;
	width: 100%;
	color: white;
	transform-origin: top left;
}

.contact-form input {
	background: transparent;
	color: white;
	border: 0;
	width: 100%;
}

.contact-form input:focus { outline: 0; }

.contact-form input:focus + span, .contact-form input:not(:placeholder-shown) + span {
	opacity:1;
	transform: scale(0.75) translateY(-130%);
}

/* For IE Browsers*/
.contact-form input:focus + span, .contact-form input:not(:-ms-input-placeholder) + span {
	opacity:1;
	transform: scale(0.75) translateY(-100%);
}

.contact-form .form-row-submit {
	margin: 2em 0 0;
}

.contact-form .btn {
	font-weight: 600;
}

.contact-form p.error {
    font-size: em(12);
    margin: .25em 0 0;
    color: $orange;
}

.contact-form > p.error {
    margin: 1em 0 0;
}

@include breakpoint('small') {
	.form .container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 2em;
	}

	.form .tagline { width: 50%; }
	.form .contact-form { width: 50%; margin-top: 0; }
}

@include breakpoint('medium') {
	.form .tagline { width: 40%; }
}

@include breakpoint('large') {
	.form .tagline {
		width: 50%;
		padding-right: 10%;
	}
}
