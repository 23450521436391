.who-we-are .container {
	text-align: center;
}

.who-we-are .tagline {
	margin: 0 0 2em;
}

	.who-we-are .tagline h2:before,
	.who-we-are .tagline h2:after {
		color: $teal;
	}

.who-we-are .text .logos {
	display: grid;
	grid-template-columns: auto auto auto;
	gap: 1em;
	margin: 2em auto 0;
	max-width: 50vh;
}

.who-we-are .text .logos img {
	transition: 400ms all ease-in .6s;
}
.who-we-are .text .logos img:nth-child(1) {
	transition-delay: .7s;
}

.who-we-are .text .logos img:nth-child(2) {
	transition-delay: .8s;
}

.who-we-are .text .logos img:nth-child(3) {
	transition-delay: .9s;
}

.who-we-are .text .logos img:nth-child(4) {
	transition-delay: 1s;
}

.who-we-are .text .logos img:nth-child(5) {
	transition-delay: 1.1s;
}
.who-we-are .text .logos img:nth-child(6) {
	transition-delay: 1.2s;
}

@include breakpoint('small') {

	.who-we-are .tagline h2 {
		display: inline;
		align-self: center;
		position: relative;
	}

	.who-we-are .tagline {
		max-width: 500px;
		margin: 0 auto 3em;
	}

	.who-we-are .text .logos {
		display: flex;
		flex-direction: row;
		justify-content: center;
		max-width: none;
	}
}

@include breakpoint('large') {

	.who-we-are .container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		gap: 4em;
	}

	.who-we-are .tagline {
		width: 55%;
		text-align: left;
		max-width: none;
	}

	.who-we-are .text {
		width: 45%;
		text-align: left;
	}

	.who-we-are .text .logos {
        justify-content: flex-start;
    }

}

@include breakpoint('wide') {
	.who-we-are .tagline {
		text-align: left;
	}
}
