body {
	font-family: $font-family-base;
	line-height: 1.2;
}

a {
	color: $orange;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

h1, h2, h3, h4, h5, h6, p {
	margin: 0 0 .35em;
}

p {
	margin: 0 0 1.5em;
}

h1 {
	font-size: em(28);
	font-weight: 600;

	@include breakpoint('tiny') {
		font-size: em(45);
	}

	@include breakpoint('large') {
		font-size: em(50);
	}
}

h2 {
	font-size: em(28);
	font-weight: 700;
	position: relative;

	@include breakpoint('tiny') {
		font-size: em(42);
	}

	@include breakpoint('large') {
		font-size: em(48);
	}

	&.upper { text-transform: uppercase; }
}

h3 {
	font-size: em(18);
	font-weight: 600;
	text-transform: uppercase;

	@include breakpoint('large') {
		font-size: em(22);
	}
}

h4 {
	font-size: em(16);
	font-weight: 600;

	@include breakpoint('large') {
		font-size: em(18);
	}
}

p, span {
	font-size: em(14);
	line-height: 1.6;
}

.bracketed {
	position: relative;

	&:before,
	&:after {
		content: '[';
		display: inline-block;
		position: relative;
		top: 1px;
		font-size: 1.25em;
		font-weight: 600;
		transition: all 0.5s ease;
	}

	&:after { content: ']'; }

	&:before {
		transform: translateX(-5px);
		margin-right: 0.125em;
	}

	&:after {
		transform: translateX(5px);
		margin-left: 0.125em;
	}
}