*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
$	COLOURS
========================================================================== */
/* ==========================================================================
$	FONTS
========================================================================== */
/* ==========================================================================
$	BREAKPOINTS
========================================================================== */
/* ==========================================================================
$	LAYOUT
========================================================================== */
/* ====== FUNCTIONS FONTS ====== */
/*
** Pixels to em
** Usage: font-size: em(16);
** Output: font-size: 1em;
*/
@font-face {
  font-family: "icomoon";
  src: url("/fonts/icomoon.eot?69hkpp");
  src: url("/fonts/icomoon.eot?69hkpp#iefix") format("embedded-opentype"), url("/fonts/icomoon.ttf?69hkpp") format("truetype"), url("/fonts/icomoon.woff?69hkpp") format("woff"), url("/fonts/icomoon.svg?69hkpp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e942";
}

.icon-envelop:before {
  content: "\e945";
}

.icon-test:before {
  content: "\e900";
  color: #fff;
}

.icon-optimise:before {
  content: "\e901";
  color: #fff;
}

.icon-develop:before {
  content: "\e902";
  color: #fff;
}

.icon-discover:before {
  content: "\e903";
  color: #fff;
}

/* ====== MIXINS BREAKPOINTS ====== */
body {
  background: #ffffff;
}

body {
  font-family: "Work Sans", sans-serif;
  line-height: 1.2;
}

a {
  color: #FF6326;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0 0 0.35em;
}

p {
  margin: 0 0 1.5em;
}

h1 {
  font-size: 1.75em;
  font-weight: 600;
}
@media (min-width: 520px) {
  h1 {
    font-size: 2.8125em;
  }
}
@media (min-width: 1280px) {
  h1 {
    font-size: 3.125em;
  }
}

h2 {
  font-size: 1.75em;
  font-weight: 700;
  position: relative;
}
@media (min-width: 520px) {
  h2 {
    font-size: 2.625em;
  }
}
@media (min-width: 1280px) {
  h2 {
    font-size: 3em;
  }
}
h2.upper {
  text-transform: uppercase;
}

h3 {
  font-size: 1.125em;
  font-weight: 600;
  text-transform: uppercase;
}
@media (min-width: 1280px) {
  h3 {
    font-size: 1.375em;
  }
}

h4 {
  font-size: 1em;
  font-weight: 600;
}
@media (min-width: 1280px) {
  h4 {
    font-size: 1.125em;
  }
}

p, span {
  font-size: 0.875em;
  line-height: 1.6;
}

.bracketed {
  position: relative;
}
.bracketed:before, .bracketed:after {
  content: "[";
  display: inline-block;
  position: relative;
  top: 1px;
  font-size: 1.25em;
  font-weight: 600;
  transition: all 0.5s ease;
}
.bracketed:after {
  content: "]";
}
.bracketed:before {
  transform: translateX(-5px);
  margin-right: 0.125em;
}
.bracketed:after {
  transform: translateX(5px);
  margin-left: 0.125em;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 3em 2em;
}

@media (min-width: 520px) {
  .container {
    padding: 4em 2em;
  }
}
@media (min-width: 768px) {
  .container {
    padding: 6em 2em;
  }
}
/*

@include breakpoint('large') {
	.container {
		padding: 6em 2em;
	}
}*/
.reveal {
  transition: opacity 400ms ease-in 0s, transform 400ms ease-in 0s;
  opacity: 0;
  transform: translate3d(0, 50%, 0);
}
.reveal--from-left {
  transform: translate3d(-50%, 0, 0);
}
.reveal--from-right {
  transform: translate3d(50%, 0, 0);
}

.reveal.shown {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.btn {
  background: #FF6326;
  border: 1px solid #FF6326;
  border-radius: 3px;
  color: #ffffff;
  display: inline-block;
  padding: 0.45em 1em;
}
.btn:hover {
  background: transparent;
  color: #FF6326;
  text-decoration: none;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
}

.btn--black {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
}
.btn--black:hover {
  color: #000000;
}

header {
  background: #000000;
  padding: 2em 0;
  text-align: center;
  position: relative;
  z-index: 40;
}
@media (min-width: 768px) {
  header {
    position: sticky;
    width: 100%;
    top: 0;
    background: black;
  }
}

header .container {
  display: flex;
  flex-direction: column;
  align-content: center;
  max-width: 1440px;
  padding: 0;
}
@media (min-width: 768px) {
  header .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 3em;
  }
}

header a.logo {
  display: block;
}

header nav {
  align-self: center;
  margin: 0;
}
@media (min-width: 520px) {
  header nav {
    margin: 2em 0 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
}
@media (min-width: 768px) {
  header nav {
    margin: 0;
    justify-content: flex-end;
  }
}
@media (min-width: 1280px) {
  header nav {
    gap: 2em;
  }
}

header nav a {
  color: #ffffff;
  font-weight: 600;
  font-size: 0.875em;
  display: block;
  margin: 1.5em 0;
  text-transform: uppercase;
}
header nav a:hover {
  color: #FF6326;
  text-decoration: none;
  transition: color 0.2s ease-in 0s;
}
@media (min-width: 520px) {
  header nav a {
    margin: 0;
  }
}
@media (min-width: 960px) {
  header nav a {
    font-size: 1em;
  }
}

header nav a:not(.btn) {
  display: none;
}
@media (min-width: 520px) {
  header nav a:not(.btn) {
    display: inline-block;
  }
}

footer {
  background: black;
  border-top: 1px solid #F8F8F8;
  color: white;
}

footer li,
footer span {
  font-size: 0.75em;
}

footer a {
  color: white;
}

footer .top {
  padding: 0 0 1em;
  margin: 0 0 1em;
  border-bottom: 1px solid #707070;
}

footer ul li {
  margin: 0 0 0.5em;
}

footer address {
  font-style: normal;
  font-size: 0.75em;
}

footer span.telephone {
  font-weight: 600;
}

footer .top,
footer .bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
}

footer .bottom ul li, footer .bottom ul li a {
  color: #909090;
}

footer .bottom {
  flex-direction: column-reverse;
}

footer .bottom ul:first-of-type {
  margin-bottom: 1em;
}

footer .bottom ul:first-of-type li a {
  color: white;
}

footer a span {
  font-size: 1.25em;
  font-weight: normal;
}

@media (min-width: 520px) {
  footer .top {
    align-items: flex-start;
    padding: 0 0 2em;
    margin: 0 0 2em;
  }
  footer .top ul {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0 2em;
  }
}
@media (min-width: 768px) {
  footer .top, footer .bottom {
    flex-direction: row;
    justify-content: space-between;
  }
  footer .top ul {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 0 2em;
  }
  footer .bottom ul li {
    margin-bottom: 1em;
  }
  footer .bottom .contact-details ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0 2em;
  }
  footer .bottom .contact-details ul:first-of-type {
    margin-bottom: 0;
  }
}
.banner {
  background: #000000 url("/images/banner-full.png") bottom center no-repeat;
  background-size: contain;
  position: relative;
  overflow: hidden;
}
.banner:before {
  content: "";
  background: black;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
@media (min-width: 960px) {
  .banner {
    background: #000000;
  }
}

.banner .container {
  padding-top: 0;
  padding-bottom: 0;
  max-width: 1280px;
  position: relative;
  z-index: 4;
}
.banner .container:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 59.6153846154%;
}
@media (min-width: 960px) {
  .banner .container:before {
    padding-top: 43.0555555556%;
  }
}

.banner .container .content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2em;
}

.banner .container .content h1 {
  text-align: center;
}
.banner .container .content h1:before, .banner .container .content h1:after {
  color: #FF6326;
}

@media (min-width: 768px) {
  .banner .container .content {
    padding: 0 2em;
    align-items: flex-start;
  }
  .banner .container .content h1 {
    text-align: left;
  }
}
.banner .container .parallax-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 100%;
}

.banner h1 {
  color: #ffffff;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .banner h1 {
    max-width: 55vw;
    margin: 0;
  }
}
@media (min-width: 960px) {
  .banner h1 {
    max-width: 500px;
  }
}

.banner .layer {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: transform 1s linear 0s;
  backface-visibility: hidden;
}
@media (min-width: 960px) {
  .banner .layer {
    display: block;
  }
}

.banner .layer.layer-main {
  left: 45%;
  top: 25%;
}

.banner .layer.layer-white-outline-1 {
  left: 48%;
  top: 18%;
}

.banner .layer.layer-white-outline-2 {
  top: 60%;
  left: 90%;
}

.banner .layer.layer-green-white {
  top: 55%;
  left: 75%;
}

.banner .layer.layer-orange {
  top: 75%;
  left: 100%;
}

.who-we-are .container {
  text-align: center;
}

.who-we-are .tagline {
  margin: 0 0 2em;
}

.who-we-are .tagline h2:before,
.who-we-are .tagline h2:after {
  color: #1EADA3;
}

.who-we-are .text .logos {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1em;
  margin: 2em auto 0;
  max-width: 50vh;
}

.who-we-are .text .logos img {
  transition: 400ms all ease-in 0.6s;
}

.who-we-are .text .logos img:nth-child(1) {
  transition-delay: 0.7s;
}

.who-we-are .text .logos img:nth-child(2) {
  transition-delay: 0.8s;
}

.who-we-are .text .logos img:nth-child(3) {
  transition-delay: 0.9s;
}

.who-we-are .text .logos img:nth-child(4) {
  transition-delay: 1s;
}

.who-we-are .text .logos img:nth-child(5) {
  transition-delay: 1.1s;
}

.who-we-are .text .logos img:nth-child(6) {
  transition-delay: 1.2s;
}

@media (min-width: 768px) {
  .who-we-are .tagline h2 {
    display: inline;
    align-self: center;
    position: relative;
  }
  .who-we-are .tagline {
    max-width: 500px;
    margin: 0 auto 3em;
  }
  .who-we-are .text .logos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: none;
  }
}
@media (min-width: 1280px) {
  .who-we-are .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 4em;
  }
  .who-we-are .tagline {
    width: 55%;
    text-align: left;
    max-width: none;
  }
  .who-we-are .text {
    width: 45%;
    text-align: left;
  }
  .who-we-are .text .logos {
    justify-content: flex-start;
  }
}
@media (min-width: 1440px) {
  .who-we-are .tagline {
    text-align: left;
  }
}
.what-we-do {
  background: #FF6326 url("/images/triangles.png") bottom left no-repeat;
  background-size: auto 100%;
  color: white;
  text-align: center;
}

.what-we-do .blocks {
  margin: 2em 0 0;
}

.what-we-do .block {
  margin: 0 0 2em;
}

.what-we-do .block:last-of-type {
  margin-bottom: 0;
}

.what-we-do .block .top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin: 1em 0;
}

.what-we-do .block .top .title {
  font-size: 1.125em;
  font-weight: bold;
  text-transform: uppercase;
}

.what-we-do .block .top span {
  font-size: 1.625em;
}

.what-we-do .block {
  transition: opacity 400ms ease-in 0s, transform 400ms ease-in 0s;
}
.what-we-do .block.shown:nth-child(1) {
  transition: transform 0.2s ease 0.2s, opacity 0.2s ease 0.2s;
}
.what-we-do .block.shown:nth-child(2) {
  transition: transform 0.2s ease 0.4s, opacity 0.2s ease 0.4s;
}
.what-we-do .block.shown:nth-child(3) {
  transition: transform 0.2s ease 0.6s, opacity 0.2s ease 0.6s;
}
.what-we-do .block.shown:nth-child(4) {
  transition: transform 0.2s ease 0.8s, opacity 0.2s ease 0.8s;
}

@media (min-width: 768px) {
  .what-we-do .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .what-we-do .block .top {
    justify-content: flex-start;
  }
  .what-we-do .blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
    text-align: left;
  }
  .what-we-do .blocks .block {
    width: 45%;
  }
  .what-we-do .blocks .block:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
@media (min-width: 1280px) {
  .what-we-do .container {
    flex-direction: row;
  }
  .what-we-do .tagline {
    width: 40%;
    text-align: left;
  }
  .what-we-do .blocks {
    margin: 0 0 0;
    width: 60%;
  }
}
.work-with {
  text-align: center;
}

.work-with .tagline {
  margin: 0 0 4em;
}

.work-with .tagline h2 {
  display: inline-block;
  position: relative;
  max-width: 440px;
}

.work-with .tagline h2:before,
.work-with .tagline h2:after {
  color: #FF6326;
} /*{
	content: "";
	background: $orange;
	height: 15px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 25%;
	max-width: 75px;
	transform-origin: bottom left;
	transform: rotate(55deg) translate3d(-80%,80%,0) skew(-37deg);
}*/
.work-with .logos {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2em 1em;
  margin: 3em 0 0;
  width: 100%;
}

.work-with .logos div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition-delay: 0s;
}
.work-with .logos div.shown:nth-child(1) {
  transition: transform 0.2s ease 0.1s, opacity 0.2s ease 0.2s;
}
.work-with .logos div.shown:nth-child(2) {
  transition: transform 0.2s ease 0.2s, opacity 0.2s ease 0.4s;
}
.work-with .logos div.shown:nth-child(3) {
  transition: transform 0.2s ease 0.3s, opacity 0.2s ease 0.6s;
}
.work-with .logos div.shown:nth-child(4) {
  transition: transform 0.2s ease 0.4s, opacity 0.2s ease 0.8s;
}
.work-with .logos div.shown:nth-child(5) {
  transition: transform 0.2s ease 0.5s, opacity 0.2s ease 1s;
}
.work-with .logos div.shown:nth-child(6) {
  transition: transform 0.2s ease 0.6s, opacity 0.2s ease 1.2s;
}
.work-with .logos div.shown:nth-child(7) {
  transition: transform 0.2s ease 0.7s, opacity 0.2s ease 1.4s;
}
.work-with .logos div.shown:nth-child(8) {
  transition: transform 0.2s ease 0.8s, opacity 0.2s ease 1.6s;
}
.work-with .logos div.shown:nth-child(9) {
  transition: transform 0.2s ease 0.9s, opacity 0.2s ease 1.8s;
}
.work-with .logos div.shown:nth-child(10) {
  transition: transform 0.2s ease 1s, opacity 0.2s ease 2s;
}
.work-with .logos div.shown:nth-child(11) {
  transition: transform 0.2s ease 1.1s, opacity 0.2s ease 2.2s;
}
.work-with .logos div.shown:nth-child(12) {
  transition: transform 0.2s ease 1.2s, opacity 0.2s ease 2.4s;
}

.work-with .logos div img {
  object-fit: cover;
}

@media (min-width: 768px) {
  .work-with .logos {
    grid-template-columns: auto auto auto;
  }
}
@media (min-width: 1280px) {
  .work-with .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
  }
  .work-with .tagline {
    text-align: left;
    width: 45%;
    margin: 0;
  }
  .work-with .tagline h2 {
    max-width: none;
  }
  /*
  .work-with .tagline h2:after {
  	content: "";
  	background: $orange;
  	height: 15px;
  	position: absolute;
  	bottom: -.5em;
  	left: -.5em;
  	width: 25%;
  	max-width: 75px;
  	transform-origin: bottom left;
  	transform: rotate(55deg) translate3d(-80%,80%,0) skew(-37deg);
  }
  */
  .work-with .logos {
    margin: 0;
    width: 55%;
    gap: 4em 1em;
  }
}
.quotes {
  background: #EDEDED;
}

.quotes .container .quote-slider {
  display: flex;
  flex-direction: column;
  gap: 2em;
  position: relative;
}

.quote-slider .quote {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2em;
}

.quote-slider .quote .text {
  font-size: 1.25em;
}

.quote-slider .quote .text span {
  display: block;
  font-size: 0.9em;
  font-weight: 600;
  color: #1EADA3;
}

.quote-slider .controls {
  margin: 2em auto 0;
  text-align: center;
}

.quote-slider .blaze-prev, .quote-slider .blaze-next {
  display: none;
}

.quote-slider .blaze-pagination {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
}

.quote-slider .blaze-pagination button {
  background: #CECECE;
  border: 0;
  color: #FF6326;
  text-indent: -99999px;
  height: 0.35em;
  width: 3.5em;
  display: inline-block;
  clip-path: polygon(0 0, 95% 0, 100% 100%, 5% 100%);
}
.quote-slider .blaze-pagination button.active {
  background: #FF6326;
}

@media (min-width: 768px) {
  .quote-slider .controls {
    margin-bottom: 0;
  }
}
@media (min-width: 960px) {
  .quote-slider .quote .text {
    max-width: 650px;
    margin: 0 auto;
  }
}
@media (min-width: 1280px) {
  .quotes {
    position: relative;
  }
  .quotes:after {
    content: "";
    background: #e9e9e9;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    clip-path: polygon(65% 0, 100% 0, 100% 100%, 85% 100%);
  }
  .quotes .container {
    position: relative;
    z-index: 2;
  }
  .quote-slider .quote {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2em;
    text-align: left;
  }
  .quote-slider .quote .logo {
    width: 35%;
  }
  .quote-slider .quote .text {
    width: 70%;
    padding-right: 2em;
    max-width: none;
  }
  .quote-slider .controls {
    margin-bottom: 0;
  }
  .quote-slider .controls .blaze-pagination {
    justify-content: flex-start;
  }
}
.form {
  background: #000000;
  position: relative;
}

@media (min-width: 768px) {
  .form::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0e524d;
    clip-path: polygon(12% 0, 30% 100%, 13% 100%, 0 16%, 0 0);
  }
}
.form .container {
  position: relative;
  z-index: 2;
  color: white;
}

.contact-form .form-row {
  position: relative;
  margin: 0 0 2em;
}

.contact-form label {
  position: relative;
  display: block;
  text-align: left;
  border-bottom: 1px solid white;
  padding-bottom: 0.75em;
  font-size: 0.9375em;
}

.contact-form span {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 1;
  display: block;
  width: 100%;
  color: white;
  transform-origin: top left;
}

.contact-form input {
  background: transparent;
  color: white;
  border: 0;
  width: 100%;
}

.contact-form input:focus {
  outline: 0;
}

.contact-form input:focus + span, .contact-form input:not(:placeholder-shown) + span {
  opacity: 1;
  transform: scale(0.75) translateY(-130%);
}

/* For IE Browsers*/
.contact-form input:focus + span, .contact-form input:not(:-ms-input-placeholder) + span {
  opacity: 1;
  transform: scale(0.75) translateY(-100%);
}

.contact-form .form-row-submit {
  margin: 2em 0 0;
}

.contact-form .btn {
  font-weight: 600;
}

.contact-form p.error {
  font-size: 0.75em;
  margin: 0.25em 0 0;
  color: #FF6326;
}

.contact-form > p.error {
  margin: 1em 0 0;
}

@media (min-width: 768px) {
  .form .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;
  }
  .form .tagline {
    width: 50%;
  }
  .form .contact-form {
    width: 50%;
    margin-top: 0;
  }
}
@media (min-width: 960px) {
  .form .tagline {
    width: 40%;
  }
}
@media (min-width: 1280px) {
  .form .tagline {
    width: 50%;
    padding-right: 10%;
  }
}
table.definitions {
  font-size: 0.875em;
  line-height: 1.6;
  border-top: 1px solid #EAEAEA;
}
table.definitions th {
  display: block;
  width: auto;
  font-weight: bold;
  text-align: left;
  padding: 0;
}
table.definitions td {
  display: block;
  width: auto;
  text-align: left;
  padding: 0 0 1em;
}
@media (min-width: 768px) {
  table.definitions th {
    display: table-cell;
    padding: 0.5em 1em 0.5em 0;
    border-bottom: 1px solid #EAEAEA;
  }
  table.definitions td {
    display: table-cell;
    padding: 0.5em 0;
    border-bottom: 1px solid #EAEAEA;
  }
}
table.definitions.personal-data thead {
  display: none;
}
table.definitions.personal-data tbody tr td::before {
  display: block;
  font-weight: bold;
}
table.definitions.personal-data tbody tr td:nth-child(1)::before {
  content: "Category of personal data";
}
table.definitions.personal-data tbody tr td:nth-child(2)::before {
  content: "Purpose of processing";
}
table.definitions.personal-data tbody tr td:nth-child(3)::before {
  content: "Lawlful basis for processing";
}
table.definitions.personal-data tbody tr td:nth-child(4)::before {
  content: "Retention period";
}
table.definitions.personal-data tbody tr {
  display: block;
  border-bottom: 1px solid #EAEAEA;
  padding-top: 1em;
}
@media (min-width: 768px) {
  table.definitions.personal-data {
    border-left: 1px solid #EAEAEA;
    border-right: 1px solid #EAEAEA;
    border-collapse: collapse;
    margin: 2em 0;
  }
  table.definitions.personal-data thead {
    display: table-row-group;
  }
  table.definitions.personal-data thead tr th,
  table.definitions.personal-data tbody tr td {
    padding: 0.5em 1em;
    border: 1px solid #EAEAEA;
  }
  table.definitions.personal-data tbody tr td::before {
    display: none;
  }
  table.definitions.personal-data tbody tr {
    display: table-row;
    border-bottom: none;
    padding-top: 0;
  }
}

.list--report {
  counter-reset: list list2 list3 list4;
}
.list--report [data-report-level] .counter {
  padding-left: 1.5em;
  position: relative;
  counter-increment: list;
}
.list--report [data-report-level] .counter::before {
  content: counter(list);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
}
.list--report [data-report-level="1"] {
  counter-reset: list list2 list3 list4;
}
.list--report [data-report-level="1"] .counter::before {
  text-transform: uppercase;
}
.list--report [data-report-level="2"] {
  counter-reset: list2 list3 list4;
}
.list--report [data-report-level="2"] .counter {
  counter-increment: list2;
  padding-left: 2.5em;
}
.list--report [data-report-level="2"] .counter::before {
  content: counter(list) "." counter(list2);
}
.list--report [data-report-level="3"] {
  counter-reset: list3 list4;
  margin-left: 2.5em;
}
.list--report [data-report-level="3"] .counter {
  counter-increment: list3;
  padding-left: 3.5em;
}
.list--report [data-report-level="3"] .counter::before {
  content: counter(list) "." counter(list2) "." counter(list3);
}
.list--report [data-report-level="4"] {
  margin-left: 3.5em;
  counter-reset: list4;
}
.list--report [data-report-level="4"] .counter {
  counter-increment: list4;
  padding-left: 4.5em;
}
.list--report [data-report-level="4"] .counter::before {
  content: counter(list) "." counter(list2) "." counter(list3) "." counter(list4);
}