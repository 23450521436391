header {
	background: $black;
	padding: 2em 0;
	text-align: center;
	position: relative;
	z-index: 40;

	@include breakpoint('small') {
		position: sticky;
		width: 100%;
		top: 0;
		background: black;
	}
}

header .container {
	display: flex;
	flex-direction: column;
	align-content: center;
	max-width: 1440px;
	padding: 0;

	@include breakpoint('small') {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0 3em;
	}
}

header a.logo {
	display: block;
}

header nav {
	align-self: center;
	margin: 0;

	@include breakpoint(tiny) {
		margin: 2em 0 0;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 1em;
	}

	@include breakpoint(small) {
		margin: 0;
		justify-content: flex-end;
	}

	@include breakpoint(large) {
		gap: 2em;
	}
}

header nav a {
	color: $white;
	font-weight: 600;
	font-size: em(14);
	display: block;
	margin: 1.5em 0;
	text-transform: uppercase;

	&:hover {
		color: $orange;
		text-decoration: none;
		transition: color .2s ease-in 0s;
	}

	@include breakpoint('tiny') { margin: 0; }
	@include breakpoint('medium') { font-size: em(16); }
}

header nav a:not(.btn) {
	display: none;

	@include breakpoint('tiny') {
		display: inline-block;
	}
}