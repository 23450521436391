
footer {
	background: black;
	border-top: 1px solid #F8F8F8;
	color: white;
}
footer li,
footer span { font-size: em(12); }
footer a {
	color: white;
}

footer .top {
	padding: 0 0 1em;
	margin: 0 0 1em;
	border-bottom: 1px solid #707070;
}

footer ul li { margin: 0 0 .5em; }

footer address { font-style: normal; font-size: em(12); }
footer span.telephone { font-weight: 600; }

footer .top,
footer .bottom {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1em;
}

footer .bottom ul li, footer .bottom ul li a { color: #909090; }

footer .bottom {
	flex-direction: column-reverse;
}

footer .bottom ul:first-of-type { margin-bottom: 1em; }
footer .bottom ul:first-of-type li a { color: white; }

footer a span { font-size: em(20); font-weight: normal; }

@include breakpoint('tiny') {

	footer .top { align-items: flex-start; padding: 0 0 2em; margin: 0 0 2em; }

	footer .top ul {
		display: grid;
		grid-template-columns: auto auto auto;
		gap: 0 2em;
	}
}

@include breakpoint('small') {

	footer .top, footer .bottom {
		flex-direction: row;
		justify-content: space-between;
	}

	footer .top ul {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		gap: 0 2em;
	}

	footer .bottom ul li { margin-bottom: 1em; }

	footer .bottom .contact-details ul {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		gap: 0 2em;

		&:first-of-type { margin-bottom: 0; }
	}
}